@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap%27");

@layer base {
  html {
    font-family: "Poppins", system-ui, sans-serif;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.animated-background {
  background-size: 400%;

  -webkit-animation: animation 10s ease infinite;
  -moz-animation: animation 10s ease infinite;
  animation: animation 10s ease infinite;
}

@keyframes animation {
  0%,
  100% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }
}
